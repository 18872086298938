import React from 'react'

const Dir = () => (
    <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.6 10.8C3.6 9.84523 3.97928 8.92956 4.65441 8.25443C5.32955 7.5793 6.24522 7.20001 7.2 7.20001H14.4L18 10.8H25.2C26.1548 10.8 27.0705 11.1793 27.7456 11.8544C28.4207 12.5296 28.8 13.4452 28.8 14.4V16.2H14.4C12.9678 16.2 11.5943 16.7689 10.5816 17.7816C9.56893 18.7943 9 20.1678 9 21.6V24.3C9 25.0161 8.71554 25.7029 8.20919 26.2092C7.70284 26.7155 7.01608 27 6.3 27C5.58391 27 4.89716 26.7155 4.39081 26.2092C3.88446 25.7029 3.6 25.0161 3.6 24.3V10.8Z"
            fill="currentColor"
        />
        <path
            d="M10.8 21.6C10.8 20.6452 11.1793 19.7295 11.8544 19.0544C12.5295 18.3793 13.4452 18 14.4 18H28.8C29.7548 18 30.6704 18.3793 31.3456 19.0544C32.0207 19.7295 32.4 20.6452 32.4 21.6V25.2C32.4 26.1548 32.0207 27.0705 31.3456 27.7456C30.6704 28.4207 29.7548 28.8 28.8 28.8H3.6H7.2C8.15478 28.8 9.07045 28.4207 9.74558 27.7456C10.4207 27.0705 10.8 26.1548 10.8 25.2V21.6Z"
            fill="currentColor"
        />
    </svg>
)

export default Dir
