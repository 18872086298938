/* eslint-disable no-nested-ternary */
import React from 'react'
import tw from 'twin.macro'
import { Layout } from '../components/layout'
import Meta from '../components/layout/meta'
import { RelativeContainer, ComponentContainer, ColouredBand, ColoredBackgroundWithProps } from '../components/shared/landingPages/layoutComponents'
import Header from '../components/shared/landingPages/header'
import TopHighlights from '../components/shared/landingPages/topHighlights'
import HighlightedFunctionality from '../components/shared/landingPages/highlightedFunctionalityOld'
import AdvancedFunctionality from '../components/shared/landingPages/advancedFunctionality'
import FinalCTA from '../components/shared/landingPages/finalCTA'
import Pages from '../images/offertelp/pages'
import Group from '../images/offertelp/group'
import Dir from '../images/offertelp/dir'
import Graph from '../images/offertelp/graph'
import offertePng from '../images/offertelp/offerteheader.png'
import quotePng from '../images/offertelp/quote.png'
import everythingPng from '../images/offertelp/everything.png'
import completePng from '../images/offertelp/complete.png'
import doubtPng from '../images/offertelp/doubt.png'

const topHighlightData = {
    title: `Offertes maken met Tellow`,
    points: [
        {
            Icon: Pages,
            title: `Professionele offertes`,
            body: `
        Een professionele offerte opmaken en versturen naar potentiële klanten is zo geregeld.
        `,
        },
        {
            Icon: Group,
            title: `Klanten binnenhalen`,
            body: `
        Professionele offertes worden sneller geaccepteerd. Je haalt dus gemakkelijk nieuwe klanten binnen.
        `,
        },
        {
            Icon: Dir,
            title: `Stroomlijn je offertes`,
            body: `
        Offerte geaccepteerd? Zet de offerte dan direct om in een factuur.
        `,
        },
        {
            Icon: Graph,
            title: `Handig adresboek`,
            body: `
        Sla je contacten op en verifieer kvk-nummers.
        `,
        },
    ],
}

const headerData = {
    announcer: `Dé online boekhoudtool voor zzp’ers`,
    title: `Gemakkelijk offertes maken en goedkeuren`,
    text: `Creëer en verstuur offertes en facturen, verwerk bonnen automatisch en regel eenvoudig je zaken bij de belastingdienst.`,
    textCTA: `Gratis aanmelden`,
    headerImage: offertePng,
}

const highlightedFunctionalityData = {
    title: `Maak nu je offertes met Tellow`,
    firstPoint: `Professioneel`,
    firstDescription: `Gebruik standaard templates of ontwerp offertes met je eigen logo en bedrijfskleuren.`,
    secondPoint: `Bespaar tijd`,
    secondDescription: `Na goedkeuring wordt de offerte automatisch omgezet in een factuur.`,
    firstStepImage: quotePng,
}

const advancedFunctionalityData = {
    title: `Ontvang sneller je geld met Tellow`,
    subtitlePartOne: `Gebruik Tellow online via desktop of via onze handige app. `,
    subtitlePartTwo: `Je eerste offerte versturen is zo gepiept.`,
    firstAnnouncer: `Altijd compleet`,
    firstDescription: `De offertes die je verstuurt via Tellow zijn volledig en voldoen aan alle eisen. Een uniek offertenummer, de voorwaarden, het btw-tarief, en alle andere verplichte gegevens staan op de offerte.`,
    getMoneyOneImage: completePng,
    secondAnnouncer: `Alles bij de hand`,
    secondDescription: `Voeg eenvoudig de algemene voorwaarden of andere specificaties toe als bijlage. Zo heeft je klant alle benodigde informatie bij de hand.`,
    getMoneyTwoImage: everythingPng,
}

const finalCTAData = {
    title: `Twijfel je nog?`,
    text: `Tellow heeft tal van andere functionaliteiten zoals, bonnen scannen, officiële integratie met de Belastingdienst, facturen maken, automatische bankkoppeling en nog veel meer.`,
    finalImage: doubtPng,
}

/**
 * @deprecated
 * Remove when marketing converts ads
 * to go to 'functies/offertes'.
 */
const OfferteSoftware = () => (
    <Layout>
        {/* Page not indexed */}
        <Meta
            name="robots"
            indexed="noindex"
            path="/offerte-software"
            title="Offerte software | Tellow.nl"
            description="Snel online boekhouden met hét online boekhoudprogramma voor zzp'ers ✅ 100.000+ ondernemers gingen je voor ✅ Registreer gratis!"
        />
        {/* First Component : Header (Main title and image) */}
        <ColoredBackgroundWithProps>
            <RelativeContainer>
                <ComponentContainer>
                    <Header data={headerData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        {/* Purple band  */}
        <ColouredBand purple />

        {/* Second Component : Quotes Step (4 points) */}
        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <TopHighlights data={topHighlightData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        {/* Third Component : First Quote (with Design) */}
        <RelativeContainer>
            <RelativeContainer>
                <ComponentContainer>
                    <HighlightedFunctionality data={highlightedFunctionalityData} />
                </ComponentContainer>
            </RelativeContainer>
        </RelativeContainer>

        {/* Fourth Component : Get Money (I've got a lot of work to do) */}
        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <AdvancedFunctionality data={advancedFunctionalityData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        {/* Purple band */}
        <ColouredBand purple />

        {/* Fifth Component : Doubt? */}
        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer css={tw`my-0`}>
                    <FinalCTA data={finalCTAData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>
    </Layout>
)

export default OfferteSoftware
