import React from 'react'
import tw from 'twin.macro'

const Group = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple mb-1`} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.5999 14.4C21.5999 16.3096 20.8413 18.1409 19.4911 19.4912C18.1408 20.8414 16.3095 21.6 14.3999 21.6C12.4903 21.6 10.659 20.8414 9.30873 19.4912C7.95847 18.1409 7.1999 16.3096 7.1999 14.4C7.1999 12.4905 7.95847 10.6591 9.30873 9.30884C10.659 7.95858 12.4903 7.20001 14.3999 7.20001C16.3095 7.20001 18.1408 7.95858 19.4911 9.30884C20.8413 10.6591 21.5999 12.4905 21.5999 14.4ZM40.7999 14.4C40.7999 15.3455 40.6137 16.2818 40.2518 17.1553C39.89 18.0289 39.3597 18.8226 38.6911 19.4912C38.0225 20.1598 37.2288 20.6901 36.3552 21.0519C35.4817 21.4138 34.5454 21.6 33.5999 21.6C32.6544 21.6 31.7181 21.4138 30.8446 21.0519C29.971 20.6901 29.1773 20.1598 28.5087 19.4912C27.8402 18.8226 27.3098 18.0289 26.948 17.1553C26.5861 16.2818 26.3999 15.3455 26.3999 14.4C26.3999 12.4905 27.1585 10.6591 28.5087 9.30884C29.859 7.95858 31.6903 7.20001 33.5999 7.20001C35.5095 7.20001 37.3408 7.95858 38.6911 9.30884C40.0413 10.6591 40.7999 12.4905 40.7999 14.4ZM31.0319 40.8C31.1423 40.0152 31.1999 39.216 31.1999 38.4C31.2055 34.6293 29.9369 30.9673 27.5999 28.008C29.4241 26.9548 31.4934 26.4003 33.5998 26.4003C35.7062 26.4003 37.7755 26.9548 39.5998 28.0079C41.424 29.0611 42.9389 30.5759 43.9921 32.4001C45.0453 34.2243 45.5998 36.2936 45.5999 38.4V40.8H31.0319ZM14.3999 26.4C17.5825 26.4 20.6347 27.6643 22.8852 29.9147C25.1356 32.1652 26.3999 35.2174 26.3999 38.4V40.8H2.3999V38.4C2.3999 35.2174 3.66418 32.1652 5.91462 29.9147C8.16506 27.6643 11.2173 26.4 14.3999 26.4Z"
            fill="currentColor"
        />
    </svg>
)

export default Group
